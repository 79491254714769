import * as React from "react";
// import { destroyCookie } from "nookies";
import { MDBBtn, MDBIcon } from "mdbreact";
import { useNavigate } from "react-router-dom";
import LoginContext from "../../context/login/loginContext";

function ResetPasswordSuccess() {
  let navigate = useNavigate();

  const { user, setUser } = React.useContext(LoginContext);

  function backToLogin() {
    localStorage.removeItem("resetingPassword");
    // destroyCookie(null, "resetingPassword");
    setUser({ ...user, id: "", type: "", logoutMsg: "" });
    navigate("/login", { replace: true });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="my-4"
    >
      <MDBIcon far size="5x" icon="check-circle" className="green-text" />
      <h2 style={{ fontWeight: "bold" }} className="mt-2">
        Password Changed
      </h2>
      <h5>Your password has been changed successfully</h5>
      <div className="mt-4">
        <MDBBtn onClick={backToLogin} color="primary">
          Back to Login Page
        </MDBBtn>
      </div>
    </div>
  );
}

export default ResetPasswordSuccess;
