import * as React from "react";
// import { BrowserRouter as Router } from "react-router-dom";
import { MDBContainer } from "mdbreact";
// import ClassbookLogo from "../../assets/logos/classbookLogoWhite.png";
// import CBLogo from "../../assets/logos/CBlogo.png";

import "../main.css";

//components import
import LoginForm from "../../components/login/LoginForm";

function StudentHome() {
  return (
    <MDBContainer
      fluid
      className="p-0 d-flex align-items-center justify-content-center flex-column"
      style={{ height: "100vh", backgroundColor: "#5c2826" }}
    >
      <div className="mb-2 classbook-link-container">
        <a className="classbook-link" href="https://eight-tones.com.sg">
          Back to Home
        </a>
      </div>
      <div>
        {/* <Router> */}
        <LoginForm />
        {/* </Router> */}
      </div>
      <span className="mt-2 text-white classbook"> Powered by ClassBook </span>

      {/* <div className="mt-auto">
        <div className="d-flex align-items-center">
          <sm className="classbook mr-1">Powered by</sm>
          <img
            src={ClassbookLogo}
            style={{ height: "13px" }}
            className="ml-0"
          />
        </div>

        <div className="d-flex align-items-center">
          <sm className="classbook mr-1">Powered by ClassBook</sm>
          <img src={CBLogo} style={{ height: "14px" }} className="ml-0" />
        </div>
      </div> */}
    </MDBContainer>
  );
}

export default StudentHome;
