import { Component } from "react";
import { Outlet } from "react-router-dom";
import { MDBContainer } from "mdbreact";
import "../../components/students/navbar/navbar.css";
import StudentNavigationBar from "../../components/students/navbar/StudentNavigationBar";

class StudentIndex extends Component {
  state = {
    toggleStateA: false,
    breakWidth: 1300,
    windowWidth: 0,
  };

  componentDidMount = () => {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.handleResize);
  };

  handleResize = () =>
    this.setState({
      windowWidth: window.innerWidth,
    });

  render() {
    // set the style of the area where content is shown
    const { breakWidth, windowWidth } = this.state;
    const mainStyle = {
      margin: "0 1.5%",
      paddingTop: "2rem",
      paddingLeft: windowWidth > breakWidth ? "240px" : "0",
    };
    // end of style setting

    return (
      <>
        {/* Display Navbar */}
        <StudentNavigationBar />
        {/* end */}

        {/* <NewStudentNavbar /> */}
        {/* This is where the content is shown in the page */}
        <main style={mainStyle}>
          <MDBContainer fluid style={{ height: "auto" }}>
            <Outlet />
          </MDBContainer>
        </main>
        {/* end */}
      </>
    );
  }
}

export default StudentIndex;
