import { lazy, useCallback, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

//LOGIN
import Login from "../pages/login/LoginPage";
import ResetPassword from "../pages/reset_password/ResetPassword";
import LoadingCard from "../components/loading_and_error/LoadingCard";
import LoadingSpinner from "../components/loading_and_error/LoadingSpinner";
import NotFound from "../pages/NotFound";
import StudentIndex from "../pages/student/StudentIndex";
import TeacherIndex from "../pages/teacher/TeacherIndex";
import AdminIndex from "../pages/admin/AdminIndex";
import ResetPasswordIndex from "../pages/reset_password/ResetPasswordIndex";

// ===== LAZY LOAD STUDENTS =====
const StudentHome = lazy(() =>
  import("../pages/student/student_home/StudentHome")
);
const StudentLessons = lazy(() =>
  import("../pages/student/student_lessons/StudentLessons")
);
const StudentProfile = lazy(() =>
  import("../pages/student/student_profile/StudentProfile")
);

// ===== LAZY LOAD TEACHERS =====
const TeacherHome = lazy(() =>
  import("../pages/teacher/teacher_home/TeacherHome")
);
const TeacherNotifications = lazy(() =>
  import("../pages/teacher/teacher_notifications/TeacherNotifications")
);
const TeacherStudentList = lazy(() =>
  import("../pages/teacher/teacher_studentList/TeacherStudentList")
);
const TeacherStudentListDetails = lazy(() =>
  import("../pages/teacher/teacher_studentList/TeacherStudentDetails")
);
const TeacherPastLessons = lazy(() =>
  import("../pages/teacher/teacher_pastLessons/TeacherPastLessons")
);
const TeacherProfile = lazy(() =>
  import("../pages/teacher/teacher_profile/TeacherProfile")
);

// ===== LAZY LOAD ADMIN =====
const AdminHome = lazy(() => import("../pages/admin/admin_home/AdminHome"));
const AdminNotifications = lazy(() =>
  import("../pages/admin/admin_notifications/AdminNotifications")
);
const AdminNotificationUnread = lazy(() =>
  import("../pages/admin/admin_notifications/AdminNotificationUnread")
);
const AdminNotificationAll = lazy(() =>
  import("../pages/admin/admin_notifications/AdminNotificationAll")
);
const AdminUsers = lazy(() => import("../pages/admin/admin_users/AdminUsers"));
const AdminUserStudent = lazy(() =>
  import("../pages/admin/admin_users/AdminUserStudent")
);
const AdminUserTeacher = lazy(() =>
  import("../pages/admin/admin_users/AdminUserTeacher")
);
const AdminUserAdmin = lazy(() =>
  import("../pages/admin/admin_users/AdminUserAdmin")
);
const EditStudent = lazy(() =>
  import("../pages/admin/admin_users/EditStudent")
);
const EditTeacher = lazy(() =>
  import("../pages/admin/admin_users/EditTeacher")
);
const EditAdmin = lazy(() => import("../pages/admin/admin_users/EditAdmin"));
const AdminLessons = lazy(() =>
  import("../pages/admin/admin_lessons/AdminLessons")
);
const AdminAllLessons = lazy(() =>
  import("../pages/admin/admin_lessons/AdminAllLessons")
);
const AdminRecentLessons = lazy(() =>
  import("../pages/admin/admin_lessons/AdminRecentLessons")
);
const AdminLessonsEdit = lazy(() =>
  import("../pages/admin/admin_lessons/EditAdminLessons")
);
const AdminInstruments = lazy(() =>
  import("../pages/admin/admin_instruments/AdminInstruments")
);
const AdminPackages = lazy(() =>
  import("../pages/admin/admin_packages/AdminPackages")
);
const EditInstrument = lazy(() =>
  import("../pages/admin/admin_instruments/EditAdminInstruments")
);
const EditPackages = lazy(() =>
  import("../pages/admin/admin_packages/EditAdminPackages")
);

const studentRoutes = [
  { path: "", index: true, element: <StudentHome /> },
  { path: "lessons", index: false, element: <StudentLessons /> },
  { path: "profile", index: false, element: <StudentProfile /> },
];

const teacherRoutes = [
  { path: "", index: true, element: <TeacherHome /> },
  { path: "notifications", index: false, element: <TeacherNotifications /> },
  { path: "studentslist", index: false, element: <TeacherStudentList /> },
  {
    path: "studentslist/:id",
    index: false,
    element: <TeacherStudentListDetails />,
  },
  { path: "pastlessons", index: false, element: <TeacherPastLessons /> },
  { path: "profile", index: false, element: <TeacherProfile /> },
];

const adminRoutes = [
  { path: "", index: true, element: <AdminHome /> },
  /** Admin Lessons Section */
  { path: "lessons/details/:id", index: false, element: <AdminLessonsEdit /> },
  /** Admin User Details Section */
  { path: "users/students/:id", index: false, element: <EditStudent /> },
  { path: "users/teachers/:id", index: false, element: <EditTeacher /> },
  { path: "users/admins/:id", index: false, element: <EditAdmin /> },
  /** Admin Instrument Section */
  { path: "instruments", index: false, element: <AdminInstruments /> },
  { path: "instruments/:id", index: false, element: <EditInstrument /> },
  /** Admin Package Section */
  { path: "packages", index: false, element: <AdminPackages /> },
  { path: "packages/:id", index: false, element: <EditPackages /> },
];

function SuspenseFallback({ main }) {
  return (
    <div style={main ? { padding: "0rem 3rem" } : { padding: "0" }}>
      <LoadingCard mt="med" />
    </div>
  );
}

function NewRoutes() {
  const user = {
    id: localStorage.getItem("id"),
    type: localStorage.getItem("type"),
  };

  const getRootElement = useCallback(() => {
    if (localStorage.getItem("type") === "resetUser")
      return <Navigate to="/reset_password" />;
    if (localStorage.getItem("type") === "student")
      return <Navigate to="/student" />;
    if (localStorage.getItem("type") === "teacher")
      return <Navigate to="/teacher" />;
    if (localStorage.getItem("type") === "admin")
      return <Navigate to="/admin" />;
    return <Navigate to="/login" />;
  }, []);

  const getLoginElement = useCallback(() => {
    if (localStorage.getItem("type") === "resetUser")
      return <Navigate to="/reset_password" />;
    if (localStorage.getItem("type") === "student")
      return <Navigate to="/student" />;
    if (localStorage.getItem("type") === "teacher")
      return <Navigate to="/teacher" />;
    if (localStorage.getItem("type") === "admin")
      return <Navigate to="/admin" />;
    return <Login />;
  }, []);

  const isStudent = useCallback(() => {
    return user.type !== "student" ? false : true;
  }, [user.type]);

  const isTeacher = useCallback(() => {
    return user.type !== "teacher" ? false : true;
  }, [user.type]);

  const isAdmin = useCallback(() => {
    return user.type !== "admin" ? false : true;
  }, [user.type]);

  return (
    <Suspense fallback={<SuspenseFallback main />}>
      <Routes>
        <Route exact path="/" element={getRootElement()} />

        {/* ------------ */}

        {/* STUDENT PAGES */}
        <Route path="student" element={<StudentIndex />}>
          {studentRoutes.map((route) => (
            <Route
              key={route.path}
              index={route.index}
              path={route.path}
              element={
                !isStudent ? (
                  <Navigate to="/login" />
                ) : (
                  <Suspense fallback={<SuspenseFallback />}>
                    {route.element}
                  </Suspense>
                )
              }
            />
          ))}
        </Route>

        {/* TEACHER PAGES */}
        <Route path="teacher" element={<TeacherIndex />}>
          {teacherRoutes.map((route) => (
            <Route
              key={route.path}
              index={route.index}
              path={route.path}
              element={
                !isTeacher ? (
                  <Navigate to="/login" />
                ) : (
                  <Suspense fallback={<SuspenseFallback />}>
                    {route.element}
                  </Suspense>
                )
              }
            />
          ))}
        </Route>

        {/* ADMIN PAGES */}
        <Route path="admin" element={<AdminIndex />}>
          {adminRoutes.map((route) => (
            <Route
              key={route.path}
              index={route.index}
              path={route.path}
              element={
                !isAdmin ? (
                  <Navigate to="/login" />
                ) : (
                  <Suspense fallback={<SuspenseFallback />}>
                    {route.element}
                  </Suspense>
                )
              }
            />
          ))}
          {/* ADMIN NOTIFICATION */}
          <Route
            path="notifications"
            element={
              <Suspense fallback={<SuspenseFallback />}>
                <AdminNotifications />
              </Suspense>
            }
          >
            <Route index element={<Navigate to="unread" />} />
            {/* Admin Unread Notification */}
            <Route
              path="unread"
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <AdminNotificationUnread />
                </Suspense>
              }
            />
            {/* Admin All Notification */}
            <Route
              path="all"
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <AdminNotificationAll />
                </Suspense>
              }
            />
          </Route>

          {/* ADMIN USER */}
          <Route
            path="users"
            element={
              <Suspense fallback={<SuspenseFallback />}>
                <AdminUsers />
              </Suspense>
            }
          >
            <Route index element={<Navigate to="students" />} />
            {/* Admin Student User */}
            <Route
              path="students"
              element={
                <Suspense fallback={<SuspenseFallback />}>
                  <AdminUserStudent />
                </Suspense>
              }
            />

            {/* Admin Teacher User */}
            <Route
              path="teachers"
              element={
                <Suspense fallback={<SuspenseFallback />}>
                  <AdminUserTeacher />
                </Suspense>
              }
            />

            {/* Admin Admin User */}
            <Route
              path="admins"
              element={
                <Suspense fallback={<SuspenseFallback />}>
                  <AdminUserAdmin />
                </Suspense>
              }
            />
          </Route>

          {/* ADMIN LESSONS */}
          <Route
            path="lessons"
            element={
              <Suspense fallback={<SuspenseFallback />}>
                <AdminAllLessons />
              </Suspense>
            }
          >
          </Route>
        </Route>

        {/* RESET PASSWORD PAGE */}
        <Route path="/reset-password" element={<ResetPasswordIndex />}>
          <Route index element={<ResetPassword />} />
        </Route>
        {/* <Route exact path="/reset_password" element={ResetPassword}>
          {user.type !== "resetUser" && <Redirect to="/login" />}
        </Route> */}

        {/* LOGIN PAGE */}
        <Route path="/login" element={getLoginElement()} />
        {/** prevent users from accessing the login page after logging in **/}
        {/* {user.type === "student" && <Redirect to="/student" />}
          {user.type === "teacher" && <Redirect to="/teacher" />}
          {user.type === "admin" && <Redirect to="/admin" />}
          {user.type === "resetUser" && <Redirect to="/reset_password" />}
        </Route> */}

        {/* ERROR PAGE, MUST BE PUT AT THE BOTTOM */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default NewRoutes;
