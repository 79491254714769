import axios from "axios";
import { getTokenDetails } from "./formatCookies";

const URL = process.env.REACT_APP_API_URL || "http://localhost:4000";

export async function sendError(msg) {
  console.log(msg);
  const { data } = await axios.post(`${URL}/clientmonitor`, {
    message: { user: getTokenDetails(), error: msg },
  });

  return data;
}
