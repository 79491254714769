import create from "zustand";

const useUserCtx = create((set) => ({
  id: "",
  type: "",
  admin: { userDetailId: "" },
  notif: { count: 0 },
  logoutMsg: "",
  setUserCtx: (val) => set((state) => ({ ...state, ...val })),
}));

export default useUserCtx;
