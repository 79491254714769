import * as React from "react";
import { MDBSpinner } from "mdbreact";
import "./LoadingCard.css";

export default function LoadingSpinner() {
  return (
    <div className="content">
      <MDBSpinner />
      <p className="subtitle">Loading...</p>
    </div>
  );
}
