import * as React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody } from "mdbreact";
import LoadingSpinner from "./LoadingSpinner";
import "./LoadingCard.css";

function LoadingCard({ mt, mb, mx }) {
  let mt_size = "";
  if (mt === "high") mt_size = "64px";
  else if (mt === "med") mt_size = "42px";
  else if (mt === "low") mt_size = "16px";
  else mt_size = "10px";

  let mb_size = "";
  if (mb === "high") mb_size = "64px";
  else if (mb === "med") mb_size = "42px";
  else if (mb === "low") mb_size = "16px";
  else mb_size = "10px";

  let mx_size = "";
  if (mx === "high") mx_size = "18px";
  else if (mx === "med") mx_size = "12px";
  else if (mx === "low") mx_size = "8px";
  else mx_size = "0";

  return (
    <MDBContainer fluid className="p-0">
      <div
        style={{
          marginTop: mt_size,
          marginBottom: mb_size,
          marginLeft: mx_size,
          marginRight: mx_size,
          padding: 0,
        }}
      >
        <MDBRow>
          <MDBCol md="12" className="p-0 mt-4">
            <MDBCard
              style={{ borderRadius: "10px" }}
              className="PLCard p-3 m-0"
            >
              <MDBCardBody>
                <LoadingSpinner />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </div>
    </MDBContainer>
  );
}

export default LoadingCard;
