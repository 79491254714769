import * as React from "react";
import axios from "axios";
import { MDBBadge, MDBIcon } from "mdbreact";
import { useQuery } from "react-query";
import { getNotificationCount, URL } from "../../../lib/adminQuery";
import LoginContext from "../../../context/login/loginContext";
import useUserCtx from "../../../lib/store/useUserCtx";
// import { parseCookies } from "nookies";

function AdminNotificationMenu() {
  const { setUserCtx } = useUserCtx();
  const { user, setUser } = React.useContext(LoginContext);

  /** this will get notification count & store it in user context. will only run on initial page load. **/
  React.useEffect(() => {
    const fetch = async () => {
      axios
        .get(`${URL}/notification/getnotifications?id=${user.id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((res) => {
          setUser({ ...user, notif: { count: res.data.length } });
          setUserCtx({ notif: { count: res.data.length } });
        })
        .catch((err) => {
          console.log(err.response);
          console.log("AdminNotificationMenu");
        });
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** this will fetch the notification endpoint every 10 seconds and check for new notification. **/
  const notif = useQuery(
    "adminNotificationsLoop",
    () => getNotificationCount(user.id),
    { refetchInterval: 10000 }
  );

  return (
    <>
      <MDBIcon icon="fas fa-bell" className="mr-2" />
      Notifications
      {/* If the length of the new notification is higher than the one in the context, a badge will appear */}
      {notif.data?.count > user?.notif?.count && (
        <MDBBadge pill className="px-2 py-1 ml-4" color="primary">
          New
        </MDBBadge>
      )}
    </>
  );
}

export default AdminNotificationMenu;
