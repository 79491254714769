import { useState, useContext } from "react";
import {
  // MDBInput,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBAlert,
  // MDBRow,
} from "mdbreact";
import { useMutation } from "react-query";
// import { setCookie } from "nookies";
import axios from "axios";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import LoginContext from "../../context/login/loginContext";
import logoMain from "../../assets/logos/logoWithText.jpg";

const URL = process.env.REACT_APP_API_URL || "http://localhost:4000";

function LoginForm() {
  const { user, setUser } = useContext(LoginContext);
  /** state for storing username & password **/
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  /** state for storing invalid & loading status **/
  const [isInvalid, setIsInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /** request function to the backend api **/
  const loginMutation = useMutation((loginInfo) =>
    axios.post(`${URL}/auth/login`, loginInfo)
  );

  /** function for handle login button **/
  const handleLogin = (e) => {
    e.preventDefault();
    setIsInvalid(false);
    setIsLoading(true);

    /** execute login mutaiion function **/
    loginMutation.mutate(
      { username, password },
      {
        onError: (error) => {
          /** it will run when login fails **/
          setIsInvalid(true);
          setIsLoading(false);
          setUser({ ...user, logoutMsg: "" });
          console.error(error);
        },
        onSuccess: ({ data }) => {
          /** it will run when login success **/
          /** store token in browser cookies  **/
          // setCookie(null, "token", data.token, {
          //   maxAge: 12 * 60 * 60,
          //   path: "/",
          // });
          localStorage.setItem("token", data.token);
          localStorage.setItem("id", jwt_decode(data.token).id);
          localStorage.setItem(
            "type",
            jwt_decode(data.token).type.toLowerCase()
          );
          /** set user (react context) according to user info **/
          setUser({
            ...user,
            id: jwt_decode(data.token).id,
            type: jwt_decode(data.token).type.toLowerCase(),
            exp: Number(jwt_decode(data.token).expiry),
            logoutMsg: "",
          });
          setIsLoading(false);
        },
      }
    );
  };

  // function handleResetPwd() {
  //   localStorage.setItem("resetingPassword", "true");
  //   setCookie(null, "resetingPassword", "true", {
  //     maxAge: 2 * 60 * 60,
  //     path: "/",
  //   });
  //   /** set user (react context) according to user info **/
  //   setUser({ ...user, id: "reset_id", type: "resetUser", logoutMsg: "" });
  // }

  return (
    <MDBCard style={{ borderRadius: "15px" }} className="p-4">
      <a href="https://eight-tones.com.sg">
        <img src={logoMain} alt="Eight Tones Logo" />
      </a>
      <MDBCardBody className="p-0 mt-4">
        <form onSubmit={handleLogin}>
          <div
            style={{
              margin: "0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "2rem",
            }}
          >
            {/* <MDBInput
              label="Username"
              group
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            /> */}
            <FormControl variant="standard">
              <InputLabel htmlFor="login-username">Username</InputLabel>
              <Input
                id="login-username"
                type={"text"}
                value={username}
                fullWidth
                onChange={(e) => setUsername(e.target.value)}
              />
            </FormControl>

            <FormControl variant="standard">
              <InputLabel htmlFor="login-password">Password</InputLabel>
              <Input
                id="login-password"
                type={showPassword ? "text" : "password"}
                value={password}
                fullWidth
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((cv) => !cv)}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showPassword ? (
                        <i className="ml-1 fas fa-eye-slash" />
                      ) : (
                        <i className="ml-1 fas fa-eye" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          <div className="mb-1 mt-2 text-right">
            {/* <Link to="/reset_password"> */}
            {/* <p
              className="studentLink"
              onClick={handleResetPwd}
              style={{ color: "#5C2826", cursor: "pointer" }}
            >
              Forgot Password
            </p> */}
            {/* </Link> */}
            <Link
              to="/reset-password"
              className="studentLink"
              style={{ color: "#5C2826" }}
            >
              Forgot Password
            </Link>
          </div>
          <div className="p-0 mt-5 mb-1 text-center">
            <MDBBtn
              type="submit"
              disabled={isLoading}
              className="m-0 w-100"
              style={{ borderRadius: "25px" }}
            >
              {isLoading ? "Loading..." : "Login"}
            </MDBBtn>
          </div>

          {/* logout alert */}
          {!isInvalid && user.logoutMsg && (
            <div
              style={{
                opacity: user.logoutMsg ? "100%" : "0%",
                height: user.logoutMsg ? "auto" : "0",
              }}
              className="my-0"
            >
              <MDBAlert className="mt-3" color="danger">
                {user.logoutMsg}
              </MDBAlert>
            </div>
          )}
          {/* Login Error Alert */}
          {isInvalid && !user.logoutMsg && (
            <div
              style={{
                opacity: isInvalid ? "100%" : "0%",
                height: isInvalid ? "auto" : "0",
              }}
              className="my-0"
            >
              <MDBAlert className="mt-3" color="danger">
                username or password invalid
              </MDBAlert>
            </div>
          )}
        </form>
      </MDBCardBody>
    </MDBCard>
  );
}

export default LoginForm;
