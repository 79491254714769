import * as React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { MDBContainer } from "mdbreact";
// import { destroyCookie } from "nookies";
import { MDBBtn } from "mdbreact";
import LoginContext from "../../context/login/loginContext";

function ResetPasswordIndex() {
  let navigate = useNavigate();
  const { setUser } = React.useContext(LoginContext);

  function cancelResetPwd() {
    localStorage.removeItem("resetingPassword");
    // destroyCookie(null, "resetingPassword");
    setUser({
      id: "",
      type: "",
      exp: 0,
      admin: { userDetailId: "" },
      notif: { count: 0 },
      logoutMsg: "You have been logged out.",
    });

    navigate("/login", { replace: true });
  }

  return (
    <>
      <MDBContainer>
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {/* Cancel Button */}
            <div
              className="mt-4 mb-5"
              style={{ display: "flex", alignItems: "center" }}
            >
              <MDBBtn onClick={cancelResetPwd} color="primary">
                Cancel
              </MDBBtn>
              <h3 style={{ fontWeight: "bold" }} className="mt-2 ml-5">
                Reset Password
              </h3>
            </div>

            {/* Main Card */}
            <Outlet />

            {/* Balancer */}
            <div className="my-3" style={{ visibility: "hidden" }}>
              <MDBBtn onClick={cancelResetPwd} color="primary">
                Cancel
              </MDBBtn>
            </div>
          </div>
        </div>
      </MDBContainer>
    </>
  );
}

export default ResetPasswordIndex;
