import { useContext, useState } from "react";
import { MDBIcon } from "mdbreact";
// import { destroyCookie, parseCookies } from "nookies";
import axios from "axios";
import { useMutation } from "react-query";
import LoginContext from "../../context/login/loginContext";
import { sendError } from "../../lib/errorMutation";
import { useNavigate } from "react-router-dom";

const URL = process.env.REACT_APP_API_URL || "http://localhost:4000";

function LogoutLink() {
  const { setUser } = useContext(LoginContext);
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { mutate: reportError } = useMutation((msg) => sendError(msg), {
    onSuccess: () => console.log("error send to the server"),
  });

  /** request function to the backend api **/
  const logoutMutation = useMutation(() =>
    axios.post(`${URL}/auth/logout`, null, {
      /** request to protected route when needed **/
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
  );

  function handleLogout() {
    setLoading(true);
    logoutMutation.mutate(null, {
      onError: (err) => {
        reportError(err.response);
        console.log(err);
        setLoading(false);
      },
      onSuccess: (data) => {
        // destroyCookie(null, "token");
        localStorage.removeItem("id");
        localStorage.removeItem("token");
        localStorage.removeItem("type");
        setLoading(false);
        setUser({
          id: "",
          type: "",
          exp: 0,
          admin: { userDetailId: "" },
          notif: { count: 0 },
          logoutMsg: "You have been logged out.",
        });
        navigate("/login", { replace: true });
      },
    });
  }

  return (
    <div className="logout" onClick={handleLogout}>
      <MDBIcon
        icon="fas fa-sign-out-alt"
        className="pl-0 text-white d-inline-inline"
        style={{ fontSize: "16px" }}
      />{" "}
      <p className="text-white d-none d-md-inline">
        {loading ? "Logging Out" : "Log Out"}
      </p>
    </div>
  );
}

export default LogoutLink;
