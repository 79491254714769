// import { parseCookies } from "nookies";
import jwt_decode from "jwt-decode";

export const getTokenDetails = () => {
  if (!localStorage.getItem("token"))
    return { id: "unauthorized", type: "token not found" };

  const cookie = jwt_decode(localStorage.getItem("token"));

  if (cookie.id && cookie.type) {
    return { id: cookie.id, type: cookie.type };
  } else {
    return { id: "unauthorized", type: "token format invalid" };
  }
};
