import { useState } from "react";
import axios from "axios";
import { MDBBtn, MDBInput, MDBAlert } from "mdbreact";
import { useMutation } from "react-query";
import { sendError } from "../../lib/errorMutation";

const URL = process.env.REACT_APP_API_URL || "http://localhost:4000";

function VerifyToken({
  setResetPage,
  setUserId,
  username,
  setUsername,
  userId,
  token,
  setToken,
}) {
  /** state for storing invalid & loading status **/
  const [isInvalid, setIsInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { mutate: reportError } = useMutation((msg) => sendError(msg), {
    onSuccess: () => console.log("error send to the server"),
  });

  // const [isTokenReady, setIsTokenReady] = useState(true);

  // Function for failed request
  function verifyFail() {
    setIsLoading(false);
    setIsInvalid(true);
  }

  // Function for handle Next button
  async function handleNext(e) {
    e.preventDefault();
    setIsInvalid(false);
    setIsLoading(true);

    try {
      const { data } = await axios.get(
        `${URL}/adminpasswordreset/verifytoken?id=${userId}&token=${token}`
      );

      // console.log(data);
      if (data === true) {
        setResetPage(3);
        setIsLoading(false);
        setIsInvalid(false);
      } else {
        verifyFail();
      }
    } catch (e) {
      reportError(e.response);
      console.log(e);
      verifyFail();
    }
  }

  return (
    <form>
      <p className="my-4 text-center h4">Verify Token</p>
      <div className="my-2 grey-text">
        {/* <MDBInput
          label="Username"
          group
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        /> */}
        {/* Switch */}
        {/* <div className="mt-2 custom-control custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customSwitchesChecked"
            defaultChecked={isTokenReady}
            onClick={(e) => setIsTokenReady(!isTokenReady)}
          />
          <label
            style={{ color: '#000' }}
            className="custom-control-label"
            htmlFor="customSwitchesChecked"
          >
            I already have tokens
          </label>
        </div> */}

        {/* Token Input */}
        {/* {isTokenReady ? ( */}
        <MDBInput
          label="Token"
          group
          type="text"
          value={token}
          onChange={(e) => setToken(e.target.value)}
        />
        {/* ) : (
        <div className="mt-4">
          <MDBBtn disabled color="info">
            Get My Token (Coming soon)
          </MDBBtn>
        </div>
        )} */}
      </div>
      {/* Next Button */}
      <div className="mb-4 text-center">
        <MDBBtn onClick={handleNext} type="submit">
          {isLoading ? "Loading..." : "Next"}
        </MDBBtn>
      </div>

      {/* Alert when veification failed */}
      <div
        style={{
          opacity: isInvalid ? "100%" : "0%",
          height: isInvalid ? "auto" : "0",
        }}
        className="my-0"
      >
        <MDBAlert color="danger">Invalid Username or Token</MDBAlert>
      </div>
    </form>
  );
}

export default VerifyToken;
