import { Component } from "react";
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBIcon,
  MDBSideNavNav,
  MDBSideNav,
} from "mdbreact";
import { NavLink } from "react-router-dom";
import logoMain from "../../../assets/logos/logoWithText.jpg";
import LogoutLink from "../../logout/LogoutLink";

import "./navbar.css";

class StudentNavigationBar extends Component {
  state = {
    toggleStateA: false,
  };

  // THIS FUNCTION IS TO ENABLE HAMBURGER MENU
  handleToggleClickA = () => {
    const { toggleStateA } = this.state;
    this.setState({
      toggleStateA: !toggleStateA,
    });
  };

  render() {
    const { breakWidth, toggleStateA, windowWidth } = this.state;
    const navStyle = {
      paddingLeft: windowWidth > breakWidth ? "210px" : "16px",
      paddingTop: "10px",
      paddingBottom: "10px",
    };
    const specialCaseNavbarStyles = {
      WebkitBoxOrient: "horizontal",
      flexDirection: "row",
    };
    return (
      <div className="fixed-sn white-skin ">
        {/* 
            SIDE NAVIGATION SETTINGS
        */}
        <MDBSideNav
          logo={logoMain}
          triggerOpening={toggleStateA}
          breakWidth={breakWidth}
          mask="strong"
          fixed
        >
          <MDBSideNavNav>
            <NavLink className="navItem " to="/student">
              <MDBIcon icon="fas fa-home fixhome" className="mr-2" />
              Home
            </NavLink>

            <NavLink className="navItem " to="/student/lessons">
              <MDBIcon icon="fas fa-book" className="mr-2" />
              Lessons and Packages
            </NavLink>

            <NavLink className="navItem" to="/student/profile">
              <MDBIcon icon="fas fa-user" className="mr-2" />
              My Profile
            </NavLink>
          </MDBSideNavNav>
        </MDBSideNav>
        {/* 
            SIDE NAVIGATION SETTINGS END
        */}

        {/* 
            TOP NAVIGATION SETTINGS
        */}
        <MDBNavbar
          className="color1"
          style={navStyle}
          double
          expand="md"
          fixed="top"
          scrolling
        >
          <MDBNavbarNav left>
            <MDBNavItem>
              <div
                onClick={this.handleToggleClickA}
                key="sideNavToggleA"
                style={{
                  lineHeight: "0px",
                  marginRight: "1em",
                  verticalAlign: "middle",
                }}
              >
                <MDBIcon icon="bars" color="white" size="2x" />
              </div>
            </MDBNavItem>
            {/* <MDBNavItem
                className="d-none d-md-inline"
                style={{ paddingTop: 5 }}
              >
                Material Design for Bootstrap
              </MDBNavItem> */}
          </MDBNavbarNav>
          <MDBNavbarNav right style={specialCaseNavbarStyles}>
            <MDBNavItem>
              {/* <MDBDropdown>
                <MDBDropdownToggle className="text-white" nav caret>
                  <MDBIcon icon="fas fa-language text-white" />{' '}
                  <div className="text-white d-none d-md-inline">Languange</div>
                </MDBDropdownToggle>
                <MDBDropdownMenu right>
                  <MDBDropdownItem className="dropdownLang" href="#!">
                    English
                  </MDBDropdownItem>
                  <MDBDropdownItem className="dropdownLang" href="#!">
                    中文
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown> */}
            </MDBNavItem>

            <MDBNavItem className="mt-0 mb-1">
              <LogoutLink />
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBNavbar>
        {/* 
            TOP NAVIGATION SETTINGS END
        */}
      </div>
    );
  }
}

export default StudentNavigationBar;
