import { useContext, useState, useEffect } from "react";
import { MDBCard } from "mdbreact";
// import { setCookie } from "nookies";
import VerifyToken from "../../components/reset_password/VerifyToken";
import NewPasswordForm from "../../components/reset_password/NewPasswordForm";
import ResetPasswordSuccess from "../../components/reset_password/ResetPasswordSuccess";
import VerifyUsername from "../../components/reset_password/VerifyUsername";
import LoginContext from "../../context/login/loginContext";
// import SelectUserType from '../../components/reset_password/SelectUserType';

function ResetPassword() {
  const { user, setUser } = useContext(LoginContext);
  const [resetPage, setResetPage] = useState(1);
  const [username, setUsername] = useState("");
  const [token, setToken] = useState("");
  const [isFirstTime, setIsFirstTime] = useState(false);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    localStorage.setItem("resetingPassword", "true");
    // setCookie(null, "resetingPassword", "true", {
    //   maxAge: 2 * 60 * 60,
    //   path: "/",
    // });
    /** set user (react context) according to user info **/
    setUser({ ...user, id: "reset_id", type: "resetUser", logoutMsg: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(userId);
  return (
    <MDBCard className="px-5 py-4">
      {resetPage === 1 && (
        <VerifyUsername
          setResetPage={setResetPage}
          username={username}
          setUsername={setUsername}
          setUserId={setUserId}
          setIsFirstTime={setIsFirstTime}
        />
      )}

      {resetPage === 2 && (
        <VerifyToken
          setResetPage={setResetPage}
          username={username}
          setUsername={setUsername}
          setUserId={setUserId}
          token={token}
          userId={userId}
          setToken={setToken}
        />
      )}

      {resetPage === 3 && (
        <NewPasswordForm
          userId={userId}
          isFirstTime={isFirstTime}
          token={token}
          setResetPage={setResetPage}
        />
      )}

      {resetPage === 4 && <ResetPasswordSuccess />}
    </MDBCard>
  );
}

export default ResetPassword;
