import { useState } from "react";
import axios from "axios";
import { MDBBtn, MDBInput, MDBAlert } from "mdbreact";
import { useMutation } from "react-query";
import { sendError } from "../../lib/errorMutation";

const URL = process.env.REACT_APP_API_URL || "http://localhost:4000";

function NewPasswordForm({ userId, token, isFirstTime, setResetPage }) {
  const [isInvalid, setIsInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { mutate: reportError } = useMutation((msg) => sendError(msg), {
    onSuccess: () => console.log("error send to the server"),
  });

  // Function for failed request
  function verifyFail() {
    setIsLoading(false);
    setIsInvalid(true);
  }

  // Function for handle Next button
  async function handleNext(e) {
    e.preventDefault();
    setIsLoading(true);
    setIsInvalid(false);

    console.log("next");
    if (password === confirmPassword) {
      try {
        // console.log({ userId, token });

        let newPassword;
        if (isFirstTime === true) {
          // this is for first time reset passsword
          newPassword = await axios.post(
            `${URL}/adminpasswordreset/resetpasswordtoken?id=${userId}`,
            { password: confirmPassword }
          );
        } else {
          // this is reset passsword that require token
          newPassword = await axios.post(
            `${URL}/adminpasswordreset/resetpasswordtoken?id=${userId}&token=${token}`,
            { password: confirmPassword }
          );
        }

        // Check if post new password success
        if (newPassword.data === true) {
          console.log("success");
          setIsLoading(false);
          setIsInvalid(false);
          setResetPage(4);
        } else {
          // this will run when failed/invalid token
          verifyFail();
        }
      } catch (e) {
        reportError(e.response);
        console.log(e);
        // this will run when request failed/not found
        verifyFail();
      }
    } else {
      // this will run when password != confirm password
      verifyFail();
    }
  }

  return (
    <div>
      <form>
        <p className="my-4 text-center h4">Create New Password</p>
        <div className="my-2 grey-text">
          <MDBInput
            label="Password"
            group
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <MDBInput
            label="Confirm Password"
            group
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        {/* Next Button */}
        <div className="mb-4 text-center">
          <MDBBtn onClick={handleNext} type="submit">
            {isLoading ? "Loading..." : "Next"}
          </MDBBtn>
        </div>

        {/* Alert when veification failed */}
        <div
          style={{
            opacity: isInvalid ? "100%" : "0%",
            height: isInvalid ? "auto" : "0",
          }}
          className="my-0"
        >
          <MDBAlert color="danger">
            Request failed, make sure password and confirm password are the same
          </MDBAlert>
        </div>
      </form>
    </div>
  );
}

export default NewPasswordForm;
