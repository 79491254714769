import * as React from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { parseCookies } from "nookies";
import jwt_decode from "jwt-decode";
import LoginContext from "./context/login/loginContext";
import NewRoutes from "./routes/NewRoutes";
// import NotificationContext from './context/NotificationContext';
import "./pages/main.css";
// import useUserCtx from "./lib/store/useUserCtx";

/** Init react-query client **/
const queryClient = new QueryClient();
const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#5C2826",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#FFC155",
    },
  },
});

function App() {
  // const { setUserCtx } = useUserCtx();

  /** check user type in token cookies **/
  // let loginUser = { id: '', type: '' };
  let loginUser = {
    id: "",
    type: "",
    admin: { userDetailId: "" },
    notif: { count: 0 },
    logoutMsg: "",
  };

  if (!!localStorage.getItem("resetingPassword")) {
    if (loginUser.id !== "reset_id") loginUser.id = "reset_id";
    if (loginUser.type !== "resetUser") loginUser.type = "resetUser";
    // setUserCtx({ id: "reset_id", type: "resetUser" });
  }
  if (!!localStorage.getItem("token")) {
    if (loginUser.id !== jwt_decode(localStorage.getItem("token")).id) {
      loginUser.id = jwt_decode(localStorage.getItem("token")).id;
    }

    if (
      loginUser.type !==
      jwt_decode(localStorage.getItem("token")).type.toLowerCase()
    ) {
      loginUser.type = jwt_decode(
        localStorage.getItem("token")
      ).type.toLowerCase();
    }
    // setUserCtx({
    //   id: jwt_decode(localStorage.getItem("token")).id,
    //   type: jwt_decode(localStorage.getItem("token")).type.toLowerCase(),
    // });
  }

  // notif default value
  // let notificationProps = { id: '', type: '', admin: { userDetailId: '' } };

  /** set tokenType as default value in user context **/
  const [user, setUser] = React.useState(loginUser);
  /** set default value for notification count **/
  // const [globalNotif, setGlobalNotif] = React.useState(loginUser);

  // React.useEffect(() => {
  //   if (parseCookies().resetingPassword) {
  //     setUser((current) => ({
  //       ...current,
  //       id: "reset_id",
  //       type: "resetUser",
  //       exp: 0,
  //     }));
  //   }
  //   if (localStorage.getItem("token")) {
  //     setUser((current) => ({
  //       ...current,
  //       id: jwt_decode(localStorage.getItem("token")).id,
  //       type: jwt_decode(localStorage.getItem("token")).type.toLowerCase(),
  //       exp: Number(jwt_decode(localStorage.getItem("token")).expiry),
  //     }));
  //   }
  //   return;
  // }, []);

  return (
    /** Wrap entire component with react-query provider **/
    <QueryClientProvider client={queryClient}>
      <LoginContext.Provider value={{ user, setUser }}>
        <ThemeProvider theme={theme}>
          {/* <NotificationContext.Provider value> */}
          {/** MUST FIND A WAY TO DISPLAY LOGIN PAGE FIRST IF USER HAS NEVER LOGGED IN **/}
          {/** AFTER LOGGED IN, MUST FIND A WAY TO DISPLAY RELEVANT PAGES **/}
          {/** ROUTING PLAYS A HUGE ROLE, MUST FIND OUT MORE ABOUT THIS **/}
          {/** LIKE PRIVATEROUTE/INDEXROUTE/ETC **/}
          {/** IM NOT SKILLED AT THIS EITHER **/}

          {/** IF STUDENT IS LOGGED IN DISPLAY THIS **/}
          {/* {user.type === "student" && <StudentIndex />} */}

          {/** IF TEACHER IS LOGGED IN DISPLAY THIS **/}
          {/* {user.type === "teacher" && <TeacherIndex />} */}

          {/** IF ADMIN IS LOGGED IN DISPLAY THIS **/}
          {/* {user.type === "admin" && <AdminIndex />} */}

          {/* Reset Password */}
          {/* {user.type === "resetUser" && <ResetPasswordIndex />} */}

          {/** LOGIN PAGE **/}
          {/* {user.type === "" && <LoginPage />} */}

          {/** UNCOMMENT THIS TO SHOW LOGIN PAGE **/}
          {/* </NotificationContext.Provider> */}

          <NewRoutes />
        </ThemeProvider>
      </LoginContext.Provider>

      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
