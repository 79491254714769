import { useState } from "react";
import axios from "axios";
import { MDBBtn, MDBInput, MDBAlert } from "mdbreact";
import { useMutation } from "react-query";
import { sendError } from "../../lib/errorMutation";

const URL = process.env.REACT_APP_API_URL || "http://localhost:4000";

function VerifyUsername({
  setResetPage,
  setUserId,
  username,
  setUsername,
  setIsFirstTime,
}) {
  /** state for storing invalid & loading status **/
  const [isInvalid, setIsInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { mutate: reportError } = useMutation((msg) => sendError(msg), {
    onSuccess: () => console.log("error send to the server"),
  });

  // Function for failed request
  function verifyFail() {
    setIsFirstTime(false);
    setIsLoading(false);
    setIsInvalid(true);
  }

  // Function for handle Next button
  async function handleNext(e) {
    e.preventDefault();
    setIsInvalid(false);
    setIsLoading(true);

    try {
      const { data } = await axios.get(
        `${URL}/adminpasswordreset/firsttime?username=${username}`
      );

      setUserId(data._id);

      // console.log({ data });

      if (data.first_time === true) {
        // set fisrt time to true
        setIsFirstTime(true);
        // go to page 3 to input new password
        setResetPage(3);
        setIsLoading(false);
        setIsInvalid(false);
      } else if (data.first_time === false) {
        // set fisrt time to false
        setIsFirstTime(false);
        // go to page 2 to verify token
        setResetPage(2);
        setIsLoading(false);
        setIsInvalid(false);
      } else {
        verifyFail();
      }
      // setResetPage(2);
    } catch (e) {
      reportError(e.response);
      console.log(e);
      verifyFail();
    }
  }

  return (
    <form>
      <p className="my-4 text-center h4">Verify Username</p>
      <div className="my-2 grey-text">
        <MDBInput
          label="Username"
          group
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />

        {/* Switch */}
        {/* <div className="mt-2 custom-control custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customSwitchesChecked"
            checked={isTokenReady}
            onClick={(e) => setIsTokenReady(!isTokenReady)}
          />
          <label
            style={{ color: '#000' }}
            className="custom-control-label"
            htmlFor="customSwitchesChecked"
          >
            I already have tokens
          </label>*/}
      </div>

      {/* Next Button */}
      <div className="mb-4 text-center">
        <MDBBtn onClick={handleNext} type="submit">
          {isLoading ? "Loading..." : "Next"}
        </MDBBtn>
      </div>

      {/* Alert when veification failed */}
      <div
        style={{
          opacity: isInvalid ? "100%" : "0%",
          height: isInvalid ? "auto" : "0",
        }}
        className="my-0"
      >
        <MDBAlert color="danger">Invalid Username</MDBAlert>
      </div>
    </form>
  );
}

export default VerifyUsername;
